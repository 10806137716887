import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      {/* <div className="intro">
        <div className="overlay"> */}
          {/* <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  Planet Sky
                  <span></span>
                </h1>
                <p>New Automobile Trading LLC</p>
                <a
                  href="#features"
                  className="btn btn-pdf btn-lg page-scroll"
                >
                  <span>Learn More</span>
                </a>{" "}
              </div>
            </div>
          </div> */}
          <video
            className="background-video"
            width={"100%"}
            autoPlay
            playsInline
            loop
            muted
          >
            <source
              src={require("../assets/images/Add this first.mp4")}
              type="video/mp4"
            />
          </video>
        {/* </div>
      </div> */}
    </header>
  );
};
