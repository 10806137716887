import React from "react";
import Footer from "../components/Footer";
import spareParts from "../assets/images/Spare parts.png";
import busses from "../assets/images/buses and transportation.png";
import logistic from "../assets/images/Methods of Transport Presentation.png";
import trucks from "../assets/images/trucks and heavy machineries.png";

const Logistics = () => {
  return (
    <>
      <div id="services" className="text-center services">
        <div className="container">
          <div className="section-title">
            <h2>Shipping and Logistics</h2>

            <div className="row d-flex align-center justify-between my-4">
              <div className="col-md-6">
                <img src={logistic} className="w-100" />
              </div>
              <div className="col-md-6 text-left">
                <h4 className="">Shipping and Logistics</h4>
                <p>
                  As we have good relationship with the shipping lines and
                  forwarders, we provide global automotive shipping solutions at
                  every competitive price point for both individual clients with
                  single vehicle shipping requirements or commercial clients
                  with multiple vehicle shipping requirements. Our capabilities
                  feature various methods of automotive shipment to best suit
                  your needs spanning sea, air, and land.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Logistics;
