import React from "react";

const ClientSatisfaction = () => {
  return (
    <div>
      <video
        className="background-video"
        width={"100%"}
        autoPlay
        playsInline
        loop
        muted
      >
        <source
          src={require("../assets/images/Customers satisfaction.mp4")}
          type="video/mp4"
        />
      </video>
    </div>
  );
};

export default ClientSatisfaction;
