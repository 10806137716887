import React, { useEffect } from "react";
import { Header } from "../components/Header";
import { Features } from "../components/Features";
import { About } from "../components/About";
import { Services } from "../components/Services";
import { Brands } from "../components/Brands";
// import { Testimonials } from "../components/Testimonials";
// import { Team } from "../components/Team";
// import { Contact } from "../components/Contact";
import Footer from "../components/Footer";
import BodyTypes from "../components/BodyTypes";
// import Gallery from "../components/Gallery";
import Image from "../assets/images/Capture.PNG";
import Image2 from "../assets/images/Captur1e.PNG";
import ClientSatisfaction from "../components/ClientSatisfaction";

const featuredImages = [
  {
    src: Image,
    alt: "Toyota",
    title: "Toyota LC78 2.8L AT diesel 2024",
    text: `
The 2024 Toyota LC78 2.8L AT Diesel is a tough off-roader with a powerful 2.8L turbo diesel engine and automatic transmission, offering durability and reliability for any terrain.`,
    viewPdf: "/stockFiles/SPECS & Photos  Toyota LC78 2.8L AT diesel 2024.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos  Toyota LC78 2.8L AT diesel 2024.pdf",
  },
  {
    src: Image2,
    alt: "Toyota",
    title: "TOYOTA LC79 DC 4.2L V6 DIESEL PICK-UP 2024",
    text: `
The 2024 Toyota LC79 DC 4.2L V6 Diesel Pick-up is a powerful and durable vehicle designed for tough jobs. With a 4.2L V6 diesel engine, it delivers excellent performance and reliability, making it ideal for heavy-duty work and off-road adventures.`,
    viewPdf:
      "/stockFiles/SPECS & Photos TOYOTA LC79 DC 4.2L V6 DIESEL PICK-UP 2024.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos TOYOTA LC79 DC 4.2L V6 DIESEL PICK-UP 2024.pdf",
  },
];
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <Features />
      <About />
      <Services />
      <ClientSatisfaction />
      {/* <Gallery featuredImages={featuredImages} /> */}
      <Brands />
      <BodyTypes />
      {/* <Testimonials /> */}
      {/* <Team /> */}
      {/* <Contact /> */}
      <Footer />
    </div>
  );
};

export default Home;
