import React from "react";
import { Link } from "react-router-dom";

import Toyota from "../assets/images/1.png";
import Mitsubishi from "../assets/images/2.png";
import Hyundai from "../assets/images/3.png";
import Kia from "../assets/images/4-kia.png";
import Nissan from "../assets/images/5.png";
import Lexus from "../assets/images/6.png";

const featuredImages = [
  { src: Toyota, alt: "Toyota" },
  { src: Mitsubishi, alt: "Mitsubishi" },
  { src: Hyundai, alt: "Hyundai" },
  { src: Kia, alt: "Kia" },
  { src: Nissan, alt: "Nissan" },
  { src: Lexus, alt: "Lexus" },
];

export const Brands = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>FEATURED BRANDS</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {featuredImages.map((image, index) => (
              <div key={index} className="col-md-3 col-sm-6 portfolio-item">
                <img src={image.src} alt={image.alt} className="img-responsive" />
              </div>
            ))}
          </div>
          <Link to="/brands" className="btn btn-pdf btn-lg page-scroll">
            <span>View More</span>
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};
