import React, { useEffect } from "react";
import { Contact } from "../components/Contact";
import Footer from "../components/Footer";
import ContactMaps from "../components/ContactMaps";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Contact />
      <ContactMaps />
      <Footer />
    </div>
  );
};

export default ContactPage;
