import React from "react";
import sedan from "../assets/images/17qbSGGHLbE6.png";
import pickups from "../assets/images/CYRZbn86blnI.png";
import truck from "../assets/images/if2PkoW1yk3j.png";
import luxury from "../assets/images/luxury.png";
import suv from "../assets/images/pSaFIbHpzuL2.png";
import offRoad from "../assets/images/rKlRit6RBDkE.png";

const BodyTypes = () => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Body Types</h2>
          <p>
            We are able to provide all body styles to suit your customers
            requirements, if you don’t see what you need please ask
          </p>
        </div>
        <div className="row">
          <div
            className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 wow fadeInUp"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
            }}
          >
            <div className="category_item home7_style">
              <div className="thumb">
                <img src={sedan} alt="SEDAN & HATCHBACK" />
              </div>
              <div className="details">
                <p className="title">
                  <p>SEDAN & HATCHBACK</p>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 wow fadeInUp"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
            }}
          >
            <div className="category_item home7_style">
              <div className="thumb">
                <img src={offRoad} alt="Off-Road" />
              </div>
              <div className="details">
                <p className="title">
                  <p>Off Road</p>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 wow fadeInUp"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
            }}
          >
            <div className="category_item home7_style">
              <div className="thumb">
                <img src={suv} alt="SUV" />
              </div>
              <div className="details">
                <p className="title">
                  <p>SUV</p>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 wow fadeInUp"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
            }}
          >
            <div className="category_item home7_style">
              <div className="thumb">
                <img src={pickups} alt="Pick Ups" />
              </div>
              <div className="details">
                <p className="title">
                  <p>Pick Ups</p>
                </p>
              </div>
            </div>
          </div>

          <div
            className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 wow fadeInUp"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
            }}
          >
            <div className="category_item home7_style">
              <div className="thumb">
                <img src={truck} alt="Buses Vans & MPVs" />
              </div>
              <div className="details">
                <p className="title">
                  <p>Trucks & MPVs</p>
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 wow fadeInUp"
            style={{
              visibility: "visible",
              animationDuration: "1s",
              animationDelay: "0.3s",
            }}
          >
            <div className="category_item home7_style">
              <div className="thumb">
                <img src={luxury} alt="Buses Vans & MPVs" />
              </div>
              <div className="details">
                <p className="title">
                  <p>Luxury</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyTypes;
