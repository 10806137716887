import React, { useEffect } from "react";

import Hino from "../assets/images/7.png";
import Renault from "../assets/images/8.png";
import Suzuki from "../assets/images/10.png";
import Mercedes from "../assets/images/11.png";
import LandRover from "../assets/images/15.png";
import RollsRoyce from "../assets/images/Rolls-Royce.png";
import mg from "../assets/images/mg.png";
import kaiyi1 from "../assets/images/kaiyi1.png";
import jeep from "../assets/images/jeep.png";
import jetour from "../assets/images/jetour logo-01.png";
import ISUZU from "../assets/images/ISUZU.png";
import gmc from "../assets/images/gmc.png";
import gav from "../assets/images/gav.png";
import ford from "../assets/images/ford.png";
import dodge from "../assets/images/dodge.png";
import changan from "../assets/images/changan-logo.png";
import chevrolet from "../assets/images/chevrolet-logo.png";
import audi from "../assets/images/audi-logo.png";
import VW from "../assets/images/volkswagen-9.png";
import cadillac from "../assets/images/cadillac.png";
import Footer from "../components/Footer";
import Toyota from "../assets/images/1.png";
import Mitsubishi from "../assets/images/2.png";
import Hyundai from "../assets/images/3.png";
import Kia from "../assets/images/4-kia.png";
import Nissan from "../assets/images/5.png";
import Lexus from "../assets/images/6.png";
import Peugeot from "../assets/images/Peugeot-logo.png"

const allImages = [
  { src: Hino, alt: "Hino" },
  { src: Renault, alt: "Renault" },
  { src: Suzuki, alt: "Suzuki" },
  { src: Mercedes, alt: "Mercedes" },
  { src: LandRover, alt: "Land Rover" },
  { src: RollsRoyce, alt: "Rolls Royce" },
  { src: Toyota, alt: "Toyota" },
  { src: Mitsubishi, alt: "Mitsubishi" },
  { src: Hyundai, alt: "Hyundai" },
  { src: Kia, alt: "Kia" },
  { src: Nissan, alt: "Nissan" },
  { src: Lexus, alt: "Lexus" },
  { src: mg, alt: "MG" },
  { src: kaiyi1, alt: "Kaiyi" },
  { src: jetour, alt: "Jetour" },
  { src: gmc, alt: "GMC" },
  { src: ford, alt: "Ford" },
  { src: gav, alt: "GAV" },
  { src: dodge, alt: "Dodge" },
  { src: changan, alt: "Changan" },
  { src: chevrolet, alt: "Chevrolet" },
  { src: cadillac, alt: "Cadillac" },
  { src: jeep, alt: "Jeep" },
  { src: audi, alt: "Audi" },
  { src: VW, alt: "VW" },
  { src: ISUZU, alt: "ISUZU" },
  {src: Peugeot, alt:"Peugeot-logo"},
];

const Brands = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="text-center" id="brands">
        <div className="container">
          <div className="section-title">
            <h1>Brands we deal in</h1>
          </div>
          <div className="row">
            <div className="portfolio-items-page">
              {allImages.map((image, index) => (
                <div key={index} className="col-md-4 col-xs-4 portfolio-item">
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="img-responsive"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Brands;
