import React from "react";

const Footer = () => {
  return (
    <div id="footer" className="text-center">
      <div className="container">
        <div>
          <p>Copyright © 2024 planet-sky.com - All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
