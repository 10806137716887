import { useState } from "react";
import emailjs from "@emailjs/browser";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsApp from "./WhatsApp";

export const Contact = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = "service_woxtfmg";
    const templateId = "template_3c5by7f";
    const publicKey = "19BsVqUTutJaauY5g";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Planet Sky Team",
      message: message,
    };

    // Send the email using EmailJS
    emailjs
      .send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        toast.success("Message sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        toast.error("Failed to send message.");
      });
  };

  return (
    <div>
      <ToastContainer />
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Your Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    required
                    cols="30"
                    rows="10"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-pdf btn-lg">
                  <span>Send Message</span>
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address : Ras Al Khor
                  Industrial 3- , Samari Retail, building R1008, Office AF06 -
                  Dubai - United Arab Emirates
                </span>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone: +971 55 943 4740
                </span>{" "}
                <WhatsApp
                  phoneNumber="+971559434740"
                  message="Hello, I need assistance."
                />
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email:
                  Info@planet-sky.com
                </span>{" "}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
                <h2 className="text-center">Follow Us</h2>
              <div className="social">
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/planetskyautomobile?mibextid=LQQJ4d"
                      target="blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.linkedin.com/company/planet-sky-new-automobile-trading/"
                      target="blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/planet_sky_automobile?igsh=MXAyYzV3djZzNDFhNw%3D%3D&utm_source=qr"
                      target="blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
