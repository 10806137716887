import React from "react";
import Footer from "../components/Footer";
import spareParts from "../assets/images/Spare parts.png";
import busses from "../assets/images/buses and transportation.png";
import logistic from "../assets/images/Methods of Transport Presentation.png";
import trucks from "../assets/images/trucks and heavy machineries.png";

const Services = () => {
  return (
    <>
      <div id="services" className="text-center services">
        <div className="container">
          <div className="section-title">
            <h2>Our Services</h2>
            <div className="row d-flex align-center justify-between my-4">
              <div className="col-md-6 text-left">
                <h4 className="">Buses and Transportation</h4>
                <p>
                  We offer wide range of Buses and Mini-buses, either you need
                  city to city bus, city bus, airport bus, labor bus, VIP bus,
                  we can provide you a selection of capacity from 9 seaters to
                  61 seaters and more, we assure you to meet any kind of
                  transportation requirements, all needs of your plans will be
                  here with us at PLANET SKY AUTOMOBILE.
                </p>
              </div>
              <div className="col-md-6">
                <img src={busses} className="w-100" />
              </div>
            </div>
            <div className="row d-flex align-center justify-between my-4">
              <div className="col-md-6">
                <img src={trucks} className="w-100" />
              </div>
              <div className="col-md-6 text-left">
                <h4 className="">Trucks and heavy machineries</h4>
                <p>
                  We offer wide range of trucks and heavy equipment, if you are
                  mining or construction company or person, reach us always to
                  find the truck or machine that best suits your operation, we
                  provide you the best quote from PLANET SKY AUTOMOBILE, We
                  assure a highly efficient, reliable, and durable range of
                  vehicles to meet any kind of transportation requirements.
                </p>
              </div>
            </div>
           
           
          </div>
          <div className="row d-flex align-center justify-between my-4">
              <div className="col-md-6 text-left">
                <h4 className="">Spare parts</h4>
                <p>
                  We offer wide range of automotive parts for variant brand of
                  multiple selections, either cars, trucks, heavy machinery…ETC,
                  reach us always with your requirement and we will be able to
                  provide you with details and price.
                </p>
              </div>
              <div className="col-md-6">
                <img src={spareParts} className="w-100" />
              </div>
            </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Services;
