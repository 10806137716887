import React from "react";
import { Link } from "react-router-dom";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row ">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.PNG" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6 section-title">
            <div className="about-text">
              <h2>About Us</h2>
              <p>
                With over 12 years of experience, car dealer Planet Sky New
                Automobile trading LLC provides the most comprehensive and
                up-to-date portfolio of the latest vehicles. We have a wide
                range of new cars in size, engine, and color depending on the
                brand, model and year. If you are looking for a specific
                vehicle, we can make sure to acquire it for you. We offer full
                transparency with any procedure and are happy to help you every
                step of the way. With our service located in Dubai, plus
                connections around other emirates & Europe, we export our
                vehicles to many international destinations, so please don’t
                hesitate to ask about our export services for your country.{" "}
              </p>
              <Link
                  to="about"
                  className="btn btn-pdf btn-lg page-scroll"
                >
                  <span>Learn More</span>
                </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
