import React from 'react'

const ContactMaps = () => {
    return (
        <div><div className="">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.772996852685!2d55.3762882!3d25.177141700000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f677e2f12c9f7%3A0x61b6d1ac668ca108!2sPlanet%20Sky%20New%20Automobile%20Trading!5e0!3m2!1sen!2s!4v1717315990866!5m2!1sen!2s"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
            ></iframe>
        </div></div>
    )
}

export default ContactMaps