import React, { useEffect } from "react";
import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="text-center" id="services">
        <div className="container">
          <div className="section-title">
            <h2>Your Global Partner in the Automotive Industry</h2>
            <p className="">
              With over 12 years of experience, car dealer Planet Sky New
              Automobile provides the most comprehensive and up-to-date
              portfolio of the latest vehicles. We have a wide range of new cars
              in size, engine, and color depending on the brand, model and year.
              If you are looking for a specific vehicle, we can make sure to
              acquire it for you. We offer full transparency with any procedure
              and are happy to help you every step of the way. With our service
              located in Dubai, plus connections around other emirates & Europe,
              we export our vehicles to many international destinations, so
              please don’t hesitate to ask about our export services for your
              country.
            </p>
            <p>
              Want to get A+ good and standard price with minimum hassles? Then,
              we are the perfect dealer for you. Here we offer a range of models
              to choose from, whether you are looking for brand new or
              customized, or even trucks! Just let us know about your
              preferences and budget and we will provide you the best deal
              available. We offer the best exports of vehicles of all types and
              brands at an attractive price. So, get in touch with us today and
              explore our services!
            </p>
          </div>
          <div className="section-title">
            <h2>Vision</h2>
            <p className="">
              One of the most vital aspects of our operation is after sales
              service, which is also vital for a successful long-term
              relationship with our customers. Moreover, we do not compromise on
              excellence and service as our team of professionals are very well
              trained to handle problems and overcome challenges.
            </p>
            <p>
              Our aim is to provide you with the best prices of all kinds of
              brand-new vehicles as per your requirements from Dubai or in
              Europe. Our mission is to make available at your doorstep every
              type of vehicle whether it is luxury or economical one. We
              appreciate the needs of all class of people, be it a dealer,
              individual or corporate organization. We are proud to offer our
              burning passion for quality services and would like to hear your
              comments and feedback.
            </p>
          </div>
        </div>
      </div>
      <div className="about-video-container">
        <iframe
          src="https://player.vimeo.com/video/921059073?h=481e77e2df&autoplay=0&title=0&portrait=0&byline=0&badge=0"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
          title="About Us Video"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default About;
