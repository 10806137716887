import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.PNG";

export const Navigation = (props) => {
  const handleNavLinkClick = () => {
    const navbarCollapse = document.getElementById("bs-example-navbar-collapse-1");
    if (navbarCollapse.classList.contains("in")) {
      navbarCollapse.classList.remove("in");
      navbarCollapse.classList.add("collapse");
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand" to="/">
            <img src={Logo} alt="Logo" />
          </Link>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
          <li>
              <Link to="/" onClick={handleNavLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="about" onClick={handleNavLinkClick}>About Us</Link>
            </li>
            <li>
              <Link to="services" onClick={handleNavLinkClick}>Commercial Vehicles</Link>
            </li>
            <li>
              <Link to="brands" onClick={handleNavLinkClick}>Brands</Link>
            </li>
            <li>
              <Link to="inventory" onClick={handleNavLinkClick}>OUR Stock</Link>
            </li>
            <li>
              <Link to="logistics" onClick={handleNavLinkClick}>Shipping and Logistics</Link>
            </li>
            <li>
              <Link to="contact" onClick={handleNavLinkClick}>Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
