import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navigation } from "./components/Navigation";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import About from "./pages/About";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Brands from "./pages/Brands";
import Inventory from "./pages/Inventory";
import Services from "./pages/Services";
import Logistics from "./pages/Logistics";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services />} />
        <Route path="about" element={<About />} />
        <Route path="brands" element={<Brands />} />
        <Route path="logistics" element={<Logistics />} />
        <Route path="inventory" element={<Inventory />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
