import React, { useEffect } from "react";
import Footer from "../components/Footer";
import { Card, Button } from "react-bootstrap";
import Image from "../assets/images/Capture.PNG";
import Image2 from "../assets/images/Captur1e.PNG";
import Image3 from "../assets/images/LC76.PNG";
import Image4 from "../assets/images/LC78HT.PNG";
import Image5 from "../assets/images/LC78.PNG";
import Image6 from "../assets/images/SC-MT.PNG";
import Image7 from "../assets/images/Hilux2.7.PNG";
import Image8 from "../assets/images/Prado.PNG";
import Image9 from "../assets/images/Img9.PNG";
import Image10 from "../assets/images/Img10.PNG";
import Image11 from "../assets/images/Img11.PNG";

const featuredImages = [
  {
    src: Image,
    alt: "Toyota",
    title: "Toyota LC78 2.8L AT diesel 2024",
    text: `
The 2024 Toyota LC78 2.8L AT Diesel is a tough off-roader with a powerful 2.8L turbo diesel engine and automatic transmission, offering durability and reliability for any terrain.`,
    viewPdf: "/stockFiles/SPECS & Photos  Toyota LC78 2.8L AT diesel 2024.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos  Toyota LC78 2.8L AT diesel 2024.pdf",
  },
  {
    src: Image10,
    alt: "Toyota",
    title: "JETOUR X70 LUX PLUS 1.5L Petrol AT 2024 Model",
    text: `
The 2024 JETOUR X70 LUX PLUS 1.5L Petrol AT is a stylish SUV with a 1.5L engine and automatic transmission. It combines modern design, a spacious interior, and advanced features, providing a comfortable and efficient driving experience for both city and long-distance travel.`,
    viewPdf:
      "/stockFiles/SPECS & Photos Jetour X70 LUX 1.5L petrol AT 2024 Model...pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos Jetour X70 LUX 1.5L petrol AT 2024 Model...pdf",
  },
  {
    src: Image9,
    alt: "Toyota",
    title: "JETOUR X70 LUX 1.5L Petrol AT 2024 Model",
    text: `
The 2024 JETOUR X70 LUX PLUS 1.5L Petrol AT is a stylish SUV with a 1.5L engine and automatic transmission. It combines modern design, a spacious interior, and advanced features, providing a comfortable and efficient driving experience for both city and long-distance travel.`,
    viewPdf:
      "/stockFiles/SPECS & Photos Jetour X70 LUX 1.5L petrol AT 2024 Model.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos Jetour X70 LUX 1.5L petrol AT 2024 Model.pdf",
  },
  {
    src: Image2,
    alt: "Toyota",
    title: "TOYOTA LC79 DC 4.2L V6 DIESEL PICK-UP 2024",
    text: `
The 2024 Toyota LC79 DC 4.2L V6 Diesel Pick-up is a powerful and durable vehicle designed for tough jobs. With a 4.2L V6 diesel engine, it delivers excellent performance and reliability, making it ideal for heavy-duty work and off-road adventures.`,
    viewPdf:
      "/stockFiles/SPECS & Photos TOYOTA LC79 DC 4.2L V6 DIESEL PICK-UP 2024.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos TOYOTA LC79 DC 4.2L V6 DIESEL PICK-UP 2024.pdf",
  },

  {
    src: Image4,
    alt: "Toyota",
    title: "Toyota LC78 Hardtop 4.2L V6 Diesel 2024",
    text: `
The 2024 Toyota LC78 Hardtop 4.2L V6 Diesel is a rugged, high-performance off-road vehicle. Powered by a 4.2L V6 diesel engine, it offers exceptional durability, reliability, and the capability to handle tough terrains, making it ideal for both work and adventure.`,
    viewPdf:
      "/stockFiles/Specs & PhotosTOYOTA LC78 HardTopDC 4.2L V6 DIESEL PICK-UP 2024.pdf",
    downloadPdf:
      "/stockFiles/Specs & PhotosTOYOTA LC78 HardTopDC 4.2L V6 DIESEL PICK-UP 2024.pdf",
  },
  {
    src: Image5,
    alt: "Toyota",
    title: "Toyota LC78 2.8L AT diesel 2024",
    text: `
The 2024 Toyota LC78 2.8L AT Diesel is a tough off-roader with a powerful 2.8L turbo diesel engine and automatic transmission, offering durability and reliability for any terrain.`,
    viewPdf: "/stockFiles/SPECS & Photos  Toyota LC78 2.8L AT diesel 2024.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos  Toyota LC78 2.8L AT diesel 2024.pdf",
  },
  {
    src: Image3,
    alt: "Toyota",
    title: "TOYOTALAND CRUISER LC76 LX 4.2L V6 Diesel 2024",
    text: `
The 2024 Toyota Land Cruiser LC76 LX 4.2L V6 Diesel is a tough, reliable SUV built for challenging terrains. Powered by a 4.2L V6 diesel engine, it combines durability with off-road capability, making it ideal for adventure and heavy-duty use.`,
    viewPdf:
      "/stockFiles/Specs & Photos Toyota LC76 LX 4.2L V6 diesel 2024.pdf",
    downloadPdf:
      "/stockFiles/Specs & Photos Toyota LC76 LX 4.2L V6 diesel 2024.pdf",
  },
  {
    src: Image6,
    alt: "Toyota",
    title: "Toyota Hilux 2.4L Diesel SC MT 2024",
    text: `
The 2024 Toyota Hilux 2.4L Diesel SC MT is a reliable single-cab pickup equipped with a 2.4L diesel engine and manual transmission. Designed for tough tasks, it offers excellent fuel efficiency, durability, and off-road capability. Its robust build makes it ideal for both commercial and utility purposes.`,
    viewPdf:
      "/stockFiles/Specs & Photos Toyota Hilux 2.7L Petrol MT DC High Options 2024 (1).pdf",
    downloadPdf:
      "/stockFiles/Specs & Photos Toyota Hilux 2.7L Petrol MT DC High Options 2024 (1).pdf",
  },
  {
    src: Image7,
    alt: "Toyota",
    title: "Toyota Hilux 2.7L Petrol MT DC High Options 2024",
    text: `
The 2024 Toyota Hilux 2.7L Petrol MT DC High Options is a versatile pickup truck featuring a 2.7L petrol engine with a manual transmission. Known for its durability and off-road capability, this model offers a spacious double-cab design and advanced features for both work and leisure. It combines rugged performance with comfort and modern tech.`,
    viewPdf:
      "/stockFiles/Specs & Photos Toyota Hilux 2.7L Petrol MT DC High Options 2024.pdf",
    downloadPdf:
      "/stockFiles/Specs & Photos Toyota Hilux 2.7L Petrol MT DC High Options 2024.pdf",
  },
  {
    src: Image8,
    alt: "Toyota",
    title: "Toyota LC300 GR-S 3.3L Diesel 2024",
    text: `
The 2024 Toyota Land Cruiser LC300 GR-S 3.3L Diesel is a powerful off-road SUV with a 3.3L V6 turbocharged diesel engine. It features advanced off-road tech from Toyota's Gazoo Racing, including adaptive suspension and crawl control. With a premium interior and modern safety features, it blends rugged performance with comfort.`,
    viewPdf:
      "/stockFiles/SPEC & Photos Toyota LC300 GR-S 3.3L Diesel 2024 Model.pdf",
    downloadPdf:
      "/stockFiles/SPEC & Photos Toyota LC300 GR-S 3.3L Diesel 2024 Model.pdf",
  },
 
  {
    src: Image11,
    alt: "Toyota",
    title: "Prado 250 Petrol 2.4L AT TXL 2024",
    text: `
The 2024 Prado 250 Petrol 2.4L AT TXL is a powerful SUV with a 2.4L engine and automatic transmission. It offers a smooth ride, advanced safety features, and a spacious, comfortable interior, perfect for both city driving and off-road adventures.`,
    viewPdf: "/stockFiles/SPECS & Photos Prado 250 petrol 2.4L AT TXL 2024.pdf",
    downloadPdf:
      "/stockFiles/SPECS & Photos Prado 250 petrol 2.4L AT TXL 2024.pdf",
  },
  
];

const Inventory = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Our Stock</h2>
            <div className="row">
              <div className="justify-content-center">
                {featuredImages.map((image, index) => (
                  // <div key={index} className="col-md-4 col-sm-6 mb-4 h-100 my-4">
                  //   <Card className="h-100">
                  //     <Card.Img
                  //       variant="top"
                  //       src={image.src}
                  //       alt={image.alt}
                  //       style={{ maxWidth: "100%", height: "auto" }}
                  //     />
                  //     <Card.Body>
                  //       <Card.Title>{image.title}</Card.Title>
                  //       <Card.Text>{image.text}</Card.Text>
                  //       <div>
                  //         <Button
                  //           variant="secondary"
                  //           href={image.viewPdf}
                  //           target="_blank"
                  //           rel="noopener noreferrer"
                  //           className="mr-2"
                  //         >
                  //           View PDF
                  //         </Button>
                  //         <Button
                  //           variant="secondary"
                  //           href={image.downloadPdf}
                  //           download
                  //           className="btn-pdf"
                  //         >
                  //           <span>Download PDF</span>
                  //         </Button>
                  //       </div>
                  //     </Card.Body>
                  //   </Card>
                  // </div>
                  <div key={index} className="col-md-4 col-sm-6 mb-4 my-4 card h-100">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="card-img-top"
                      style={{ maxWidth: "100%", height: "250" }}
                    />
                    <div className="card-body">
                      <h5 className="card-title">{image.title}</h5>
                      <p className="card-text">{image.text}</p>
                    </div>
                    <div className="card-body">
                      <Button
                        href={image.viewPdf}
                        className="btn btn-info mr-4"
                      >
                        View PDF
                      </Button>
                      <Button
                        href={image.downloadPdf}
                        className="btn btn-warning"
                      >
                        Download PDF
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Inventory;
