import React from "react";

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <div className="about-text">
          <h2>Features</h2>
            <p>
              One of the most vital aspects of our operation is after sales
              service, which is also vital for a successful long-term
              relationship with our customers. Moreover, we do not compromise on
              excellence and service as our team of professionals are very well
              trained to handle problems and overcome challenges.
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
         
        </div>
        <p></p>
      </div>
    </div>
  );
};
