import React from "react";
import { Link } from "react-router-dom";

export const Services = (props) => {
  return (
    <div id="servicesHome" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div>
          <p>
            We are very experienced in exporting vehicles of all makes and
            models, for commercial and personal use, to almost any country in
            the world. We provide a full service including customs clearance.
            Our efficient and professional team will take care of all that is
            needed to be done before shipping your vehicle safely to its
            destination – whether it’s new, customized or big trucks – so you
            don’t need to worry yourself with the details. We also specialize in
            shipping vehicles by Roll-on/Roll-off (RoRo) and Containerized
            methods. SEA, LAND & AIR! We have been exporting vehicles for more
            than 12 years now, and have the knowledge and experience to give you
            a free quotation. We export all types of vehicles, from luxury cars
            to vans. Our duty is to do a professional business and help clients
            with the best service.
          </p>
        </div>
        <Link to="services" className="btn btn-pdf btn-lg page-scroll">
          <span>Learn More</span>
        </Link>
      </div>
    </div>
  );
};
